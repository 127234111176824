import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Text, 
//Button,
View, StyleSheet, 
//ActivityIndicator,
TouchableOpacity, Linking, Image, } from 'react-native';
import React, { useState, useEffect } from 'react';
import // getToken,
 
// postComment,
// postCommentExpert,
// postExamPaper,
// getOneExamPaperForTest,
// getExamPapersByAuthorID,
'../../Tools/Web/apiPost';
//import {getQuestion, getQuestionOne} from '../../Tools/Web/apiGet';
import { logout, deleteAccount } from '../../Tools/Web/apiPost';
import { useAuth } from '../../Tools/AuthContext';
//import {writrToken} from '../../Tools/token';
import { parseJwt } from '../../Tools/utils';
export const ProfileScreen = ({}) => {
    const act = useAuth();
    //const [isLoading, setLoading] = useState(false);
    const [username, setUserName] = useState('无名氏');
    // const [customModal2Message, setCustomModal2Message] = useState('');
    // const [customModal2Visible, setCustomModal2Visible] = useState(false);
    const [expert, setExpert] = useState(false); //是否是专家
    // if (!act) {
    //   return <View />;
    // }
    // const {
    //   accessToken,
    //   // setAccessToken,
    //   // refreshToken,
    //   // setRefreshToken,
    //   dataFlag,
    //   setDataFlag,
    // } = act;
    useEffect(() => {
        if (act) {
            if (act.accessToken === '') {
                //navigation.navigate("Home");
                //act.setNeedLogin(true);
                console.log('accessToken is empty');
                setUserName('无名氏');
            }
            else {
                const decoded = parseJwt(act.accessToken);
                const username_temp = decoded.username;
                setUserName(username_temp);
                setExpert(decoded.expert);
            }
            //setCustomModal2Message("注销后将清除您在网站上的一切信息");
        }
    }, [act?.dataFlag]); // 依赖项数组中包含了act，确保当act变化时，这个副作用能够重新执行
    // if (!accessToken) {
    //   return <View></View>;
    // }
    // const { accessToken, setAccessToken, refreshToken, setRefreshToken } = act;
    // if (accessToken) {
    //   const decoded = parseJwt(accessToken);
    //   const username_temp = decoded['username'];
    //   setUserName(username_temp);
    // }
    // else{
    //   act.setNeedLogin(true);
    //   return <View></View> ;
    // }
    const handleCancel = async () => {
        act?.hideModal2();
    };
    const handleOK = async () => {
        act?.hideModal2();
        if (act) {
            let ok = await deleteAccount(act);
            const xxx = await logout(act);
            console.log('xxx', xxx);
            act.setDataFlag(!act.dataFlag);
        }
    };
    const handleSignOut = async () => {
        console.log("handleSignOut");
        //setCustomModal2Visible(true);
        act?.showModal2("注销后将清除您在网站上的一切信息", handleOK);
    };
    const handleLogout = async () => {
        if (act) {
            // const {
            //   accessToken,
            //   setAccessToken,
            //   refreshToken,
            //   setRefreshToken,
            //   dataFlag,
            //   setDataFlag,
            // } = act;
            const xxx = await logout(act);
            console.log('xxx', xxx);
            act.setDataFlag(!act.dataFlag);
        }
    };
    const handleLogin = async () => {
        if (act) {
            act.setNeedLogin(true);
        }
    };
    const handlePress = (url) => {
        Linking.canOpenURL(url)
            .then((supported) => {
            if (supported) {
                Linking.openURL(url);
            }
            else {
                console.log("Don't know how to open URI: " + url);
            }
        })
            .catch(err => console.error('An error occurred', err));
    };
    return (_jsxs(View, { style: styles.full, children: [_jsxs(View, { style: styles.container2, children: [_jsx(Image, { source: require('../../assets/head.webp'), style: styles.avatar }), _jsx(Text, { style: styles.username, children: username }), act?.accessToken !== '' && !expert && (_jsx(TouchableOpacity, { style: styles.signoutButton, onPress: handleSignOut, children: _jsx(Text, { style: styles.logoutButtonText, children: "\u6CE8\u9500" }) }))] }), _jsxs(View, { style: styles.container, children: [act?.accessToken !== '' && (_jsx(TouchableOpacity, { onPress: handleLogout, style: styles.button, children: _jsx(Text, { style: styles.text, children: "\u767B\u51FA" }) })), act?.accessToken === '' && (_jsx(TouchableOpacity, { onPress: handleLogin, style: styles.button, children: _jsx(Text, { style: styles.text, children: "\u767B\u5F55" }) }))] })] }));
};
const styles = StyleSheet.create({
    full: {
        flex: 1,
    },
    loadingOverlay: {
        ...StyleSheet.absoluteFillObject,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // For semi-transparent background
    },
    container: {
        flex: 1,
        justifyContent: 'flex-end',
        marginBottom: 0, // 如果需要一些边距
    },
    container2: {
        flexDirection: 'row',
        alignItems: 'center',
        padding: 10, // 视图周围的填充
    },
    avatar: {
        width: 80,
        height: 80,
        borderRadius: 20, // 将头像设置为圆形
    },
    username: {
        marginLeft: 10,
        fontSize: 24,
    },
    button: {
        alignItems: 'center',
        backgroundColor: '#007bff',
        padding: 10,
        width: '100%',
        borderRadius: 0, // 可选：添加圆角
    },
    text: {
        color: '#ffffff',
    },
    beiAnStyle: {
        position: 'absolute',
        bottom: 20,
        width: '100%',
        alignItems: 'center',
        marginBottom: 20, // 底部外边距
    },
    linkText: {
        color: 'blue',
        textDecorationLine: 'underline'
    },
    signoutButton: {
        position: 'absolute',
        top: 10,
        right: 10,
        backgroundColor: '#007bff',
        borderRadius: 15,
        padding: 10, // 内边距
        // 可以根据需要添加阴影样式
    },
    logoutButtonText: {
        color: 'white',
        fontSize: 16, // 文本大小
    },
});
export default ProfileScreen;
