import { Dimensions } from 'react-native';
export const paiMap = new Map([
    ['pai-5pr', '红五饼'],
    ['pai-1p', '一饼'],
    ['pai-2p', '二饼'],
    ['pai-3p', '三饼'],
    ['pai-4p', '四饼'],
    ['pai-5p', '五饼'],
    ['pai-6p', '六饼'],
    ['pai-7p', '七饼'],
    ['pai-8p', '八饼'],
    ['pai-9p', '九饼'],
    ['pai-5sr', '红五条'],
    ['pai-1s', '一条'],
    ['pai-2s', '二条'],
    ['pai-3s', '三条'],
    ['pai-4s', '四条'],
    ['pai-5s', '五条'],
    ['pai-6s', '六条'],
    ['pai-7s', '七条'],
    ['pai-8s', '八条'],
    ['pai-9s', '九条'],
    ['pai-5mr', '红五万'],
    ['pai-1m', '一万'],
    ['pai-2m', '二万'],
    ['pai-3m', '三万'],
    ['pai-4m', '四万'],
    ['pai-5m', '五万'],
    ['pai-6m', '六万'],
    ['pai-7m', '七万'],
    ['pai-8m', '八万'],
    ['pai-9m', '九万'],
    ['pai-e', '东'],
    ['pai-s', '南'],
    ['pai-w', '西'],
    ['pai-n', '北'],
    ['pai-c', '中'],
    ['pai-p', '白'],
    ['pai-f', '发'],
    ['empty', ''],
]);
export const actionMap = new Map([
    ['Tsumo', '胡'],
    ['Skip', '过'],
    ['Riichi', '立直'],
    ['Pon', '碰'],
    ['Chii', '吃'],
    ['Kan', '杠'],
    ['Discard', '切'],
]);
export const scoreCommentMap = new Map([
    ['0', '需要更多练习，继续加油！'],
    ['1', '有些进展，但还需努力。'],
    ['2', '表现一般，有提升空间。'],
    ['3', '达到了一定水平，继续保持！'],
    ['4', '表现不错，已经接近优秀。'],
    ['5', '非常优秀，只差一步达到顶峰！'],
    ['6', '完美表现，无懈可击！'],
]);
export const dailyExamSuccess = [
    "太棒了！你答对了！",
    "恭喜你，完全正确！",
    "精彩的表现！继续保持！",
    "答对了！看来你对这个问题非常熟悉！",
    "正确答案！你真是太聪明了！",
];
export const dailyExamFail = [
    "很遗憾，答错了，别气馁，再接再厉！",
    "答案不对哦，下次一定可以答对！",
    "别灰心，这道题有点难，明天再试一次吧！",
    "可惜，这次答错了，多思考一下下次就会更好！",
    "没关系，继续努力，下次就能答对了！",
];
//export const SERVER_IP_PORT = 'http://192.168.0.103:8000';
//export const SERVER_IP_PORT = 'http://192.168.101.35:8000';
//export const SERVER_IP_PORT = 'http://175.24.229.218:8000';
//const server_develop = 'http://192.168.0.30'; //公司。本地需要启动nginx和应用服务器
const server_develop = 'http://192.168.0.104'; //家里。
const server_product = 'https://www.aiqipaiwang.com'; //连接云服务器
const server_web = 'https://www.aiqipaiwang.com'; //运行版本直接连接云服务器
export const SERVER_IP_PORT = server_product;
// Platform.OS !== 'web'
//   ? __DEV__ === true
//     ? server_develop
//     : server_product
//   : server_web;
export const QUESTION_COUNT_FOR_TEST = 4;
export const screenWidth = Dimensions.get('window').width;
export const screenHeight = Dimensions.get('window').height;
export const screenRatio = screenHeight / screenWidth;
export const picRatio = 1378 / 2559;
export const PRODUCT_VERSION = '版本号:1.0.6';
console.log('屏幕宽度:', screenWidth, '屏幕高度:', screenHeight);
